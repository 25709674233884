<template lang="">
  <div>
    <base-header class="pb-7 pt-md-6 bg-gradient-dark"></base-header>
    <div class="order-xl-1">
      <div class="card">
        <div class="card-header">
          <b-row align-v="center" slot="header" >
            <b-col cols="8">
              <h2 class="mb-0"><i class="fas fa-list"></i> ข้อมูลรายละเอียดการสั่งซื้อ </h2>
            </b-col>
          </b-row>
        </div>

        <div class="card-body">
          <div class="card custom-card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-5 mb-2"><strong>รหัสสั่งซื้อ :</strong></div>
                    <div class="col-md-7">{{item.code}}</div>
                    <div class="col-md-5 mb-2"><strong>ชื่อสินค้า :</strong></div>
                    <div class="col-md-7">{{item.customer?item.customer.name:''}}</div>
                    <div class="col-md-5 mb-2"><strong>วันที่สั่งซื้อ :</strong></div>
                    <div class="col-md-7">{{item.date_order_short}}</div>
                    <!-- <div class="col-md-5 mb-2"><strong>วันที่จัดส่ง :</strong></div>
                    <div class="col-md-7">{{item.expected_delivery_date}} {{item.expected_delivery_time}}</div> -->
                    <div class="col-md-5 mb-2"><strong>สถานะการสั่งซื้อ :</strong></div>
                    <div class="col-md-7">{{StringUtils.getTextOrderStatus(item.status_order).text}}</div>
                    <div class="col-md-5 mb-2"><strong>สถานะการชำระเงิน :</strong></div>
                    <div class="col-md-7">{{StringUtils.getTextPaymentStatus(item.status_payment).text}}</div>
                    <div class="col-md-5 mb-2"><strong>สถานะการจัดส่ง :</strong></div>
                    <div class="col-md-7">{{StringUtils.getTextDeliveryStatus(item.status_delivery).text}}</div>
                    <div class="col-md-5 mb-2"><strong>ชื่อผู้ขาย :</strong></div>
                    <div class="col-md-7">{{item.sale?item.sale.fullname:''}}</div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-5 mb-2"><strong>ชื่อร้านค้า :</strong></div>
                    <div class="col-md-7">{{item.store?item.store.name:''}}</div>
                    <div class="col-md-5 mb-2"><strong>ปัญหา :</strong></div>
                    <div class="col-md-7">{{item.issues&&item.issues.length>0?item.issues[0].topic:''}}</div>
                    <div class="col-md-5 mb-2"><strong>ประเภทปัญหา :</strong></div>
                    <div class="col-md-7">{{item.issues&&item.issues.length>0?item.issues[0].issue_type_name:''}}</div>
                    <div class="col-md-5 mb-2"><strong>ชื่อที่จัดส่ง :</strong></div>
                    <div class="col-md-7">{{item.delivery_contact}}</div>
                    <div class="col-md-5 mb-2"><strong>เบอร์ผู้ที่จัดส่ง :</strong></div>
                    <div class="col-md-7">{{item.delivery_phone}}</div>
                    <div class="col-md-5 mb-2"><strong>ที่อยู่จัดส่ง :</strong></div>
                    <div class="col-md-7">{{item.delivery_address}}</div>
                    <div class="col-md-5 mb-2"><strong>สถานที่ตั้งที่จัดส่ง :</strong></div>
                    <div class="col-md-7">{{item.delivery_location}}</div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="table-responsive padding_top_20">
                    <table class="table align-middle table-bordered">
                      <thead>
                        <tr class="text-center" >
                          <th>ลำดับ</th>
                          <th>รหัสสินค้า</th>
                          <th>รายการ</th>
                          <th>ราคาขาย</th>
                          <th>ราคารวม</th>
                          <th>อัพเซลล์</th>
                        </tr>
                      </thead>
                      <tbody>
                        <template v-for="(data, index) in item.products">
                          <tr :key="`list_order_view_${index}`">
                            <td :class="`${data.parent !== data.child ? 'text-center' : 'text-right'}`" :rowspan="data.products.length+1" style="vertical-align: top">
                              {{index+1}}
                            </td>
                            <td class= "text-center">
                              {{data.code}}
                            </td>
                            <td class="text-center">{{data.product_name}}</td>
                            <td class="text-right">{{data.price | number-decimal-format}}</td>
                            <td class="text-right">
                              {{data.total | number-decimal-format}}
                            </td>
                            <td class="text-center">{{getLabelUpsale(data.upsale)}}</td>
                          </tr>
                          <tr v-for="(d, i) in data.products" :key="`list_order_view_sub_${i}`">
                            <td class= "text-center">
                              {{d.code}}
                            </td>
                            <td class="text-center">{{d.product_name}}</td>
                            <td class="text-right">{{d.price | number-decimal-format}}</td>
                            <td class="text-right">
                              {{d.total | number-decimal-format}}
                            </td>
                            <td class="text-center">{{getLabelUpsale(d.upsale)}}</td>
                          </tr>
                        </template>
                        <tr v-if="item.products.length === 0" class="text-center">
                          <td colspan="5">ไม่มีข้อมูล</td>
                        </tr>
                      </tbody>
                    </table>
                    <div  class="col-12 text-right">
                      <p>
                        รวม : {{item.total | number-decimal-format}}
                      </p>
                      <p>
                        ค่าส่ง : {{item.service | number-decimal-format}}
                      </p>
                      <p>
                        รวมทั้งสิ้น : {{item.total | number-decimal-format}}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <span><router-link :to="'/order'" class="nav-link text-underline"> กลับ</router-link></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DateUtils from '../../../util/dateUtils';
import StringUtils from '../../../util/StringUtils';
export default {
  name: 'order-view-page-order-detail',
  data () {
    return {
      permission:{
        appSlug: 'order',
        actionData: {}
      },
      id: '',
      StringUtils: StringUtils,
      item: {
        customer: null,
        store: null,
        sale: null,
        products: []
      }
    }
  },
  methods: {
    async getOrderBill () {
      const result = await this.HttpServices.getData(`/ab_order/${this.id}`);
      if(result&&result.status.code=="200"){
        this.item = result.data;
        if(this.item.expected_delivery_date){
          this.item.expected_delivery_date = DateUtils.dateFormat(this.item.expected_delivery_date, "DD/MM/YYYY");
        }
        this.item.products.forEach(v=>{
          let total = v.price;
          v.products.forEach(vv=>{
            total += vv.price;
          });
          v.total = total;
        });
      }else{
        this.AlertUtils.alertCallback('warning', `ไม่พบข้อมูล Order`, ()=>{
          this.$router.push('/order');
        });
      }
    },
    getLabelUpsale (upsale) {
      if (upsale) {
        return 'ใช่';
      }
      return 'ไม่ใช่';
    }
  },
  async mounted() {
    await this.PermissionServices.viewPermission(this.permission.appSlug, this.$router);
    this.permission.actionData = await this.PermissionServices.getPermission(this.permission.appSlug);
    this.id = await this.SessionStorageUtils.getSession("page-order");
    await this.getOrderBill();
  },
}
</script>
